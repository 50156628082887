import Api from '@/services/Index';

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/job/logs', config);
}

const getMyLogs = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/job/logs/me', config);
}

const get = async (logId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/job/logs/' + logId);
}

const download = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/job/logs/download/' + id, null, {responseType: 'arraybuffer'});
}

const calculateExtraGraduateCourses = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/extra/graduation/courses/calculate');
}

const autoCourseRegistration = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/course/registration/auto-course-registration');
}

const autoRepeatCourseRegistration = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/course/registration/auto-repeat-course-registration');
}

const autoIrregularCourseRegistration = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/course/registration/auto-irregular-course-registration');
}

const createStudentSemesters = async (semesterId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/student/semesters/create/'+semesterId);
}

const assignDoesntHavePaymentApproval = async (semesterId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/student/semesters/assign-doesnt-have-payment-approval/'+semesterId);
}

const assignDoesntHaveCourseRegistration = async (semesterId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/student/semesters/assign-doesnt-have-course-registration/'+semesterId);
}

const importSevis = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/registrations/import-sevis', data);
}

export default {
    getAll,
    getMyLogs,
    get,
    download,

    calculateExtraGraduateCourses,
    autoCourseRegistration,
    autoRepeatCourseRegistration,
    autoIrregularCourseRegistration,
    createStudentSemesters,
    assignDoesntHavePaymentApproval,
    assignDoesntHaveCourseRegistration,
    importSevis
}
